import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";

import { Spinner } from "components";
import Table from "components/Table";
import Breadcrumbs from "components/Breadcrumbs";
import ModalFolder from "./fragment/Modal/InfoFolder";
import ModulSoal from "./fragment/Modal/SoalDetail";
import EditFolder from "./fragment/Modal/AddFolder";
import AddModule from "./fragment/AddEntry";

import { getFormatDate, useDebounce } from "utilities";
import DeleteSoal from "./fragment/Modal/DeleteSoal";
import useSoal from "services/useSoal";

import DifficultyBadge from "../../views/BankSoal/List/viewModels/useDifficulty";
import MoveFolder from "../Module/fragment/Modal/MoveFolder";

const columns = [
  {
    title: "Nama",
    isSort: true,
    key: "name",
  },
  {
    title: "Diperbarui",
    isSort: true,
    key: "updated_at",
  },
  {
    title: "Kesulitan",
    isSort: true,
    key: "difficulity",
  },
  {
    title: "Tingkatan",
    isSort: true,
    key: "grade_name",
  },
  {
    title: "Aksi",
  },
];

const defaultCrumbs: Crumb[] = [
  {
    label: "Bank Soal",
    id: 0,
  },
];

const BankSoal = () => {
  const { getList, getDetail } = useSoal();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<BrowseParams["sort_by"]>("name");
  const [sortDir, setSortDir] = useState<BrowseParams["sort_dir"]>("asc");
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showBankSoal, setShowBankSoal] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [infoFolder, setInfoFolder] = useState<BrowseItem | null>(null);
  const [detailBankSoal, setDetailBankSoal] = useState<QuestionDetail | null>(
    null
  );
  const [crumbs, setCrumbs] = useState(defaultCrumbs);
  const [parentId, setParentId] = useState(0);
  const [showMoveFolder, setShowMoveFolder] = useState(false);

  const debounceSearch = useDebounce(search);

  const getSoalData = useCallback(
    async (params?: BrowseParams) => {
      setLoading(true);
      const response = await getList(params);

      setCurrentPage(params?.page || currentPage);
      setLimit(params?.per_page || currentLimit);
      setTotal(response?.total || 0);
      setLastPage(response?.last_page || lastPage);
      setData(response?.data || []);
      setLoading(false);
    },
    [currentLimit, currentPage, lastPage, getList]
  );

  useEffect(() => {
    localStorage.removeItem("assets");
    const strLastAccessedDirectorySoal = localStorage.getItem(
      "lastAccessedDirectorySoal"
    );
    if (strLastAccessedDirectorySoal) {
      const { crumbs, parentId } = JSON.parse(strLastAccessedDirectorySoal);
      getSoalData({ page: 1, per_page: 10, directory_id: parentId });
      setCrumbs(crumbs);
      setParentId(parentId);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "lastAccessedDirectorySoal",
      JSON.stringify({ crumbs, parentId })
    );
  }, [crumbs, parentId]);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getSoalData({ page: 1, keyword: debounceSearch });
    }

    if (!debounceSearch.length) {
      getSoalData({ page: 1, keyword: "" });
    }
  }, [debounceSearch]);

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext ? currentPage + 1 : currentPage - 1;

    if (page > 0 && page <= lastPage) {
      await getSoalData({ page });
    }
  };

  const handleLimit = async (limit: string) => {
    await getSoalData({ page: 1, per_page: Number(limit) });
  };

  const clearSearch = async () => {
    setSearch("");
    await getSoalData({ page: 1, keyword: "" });
  };

  const handleSort = async (params: string) => {
    let direction: BrowseParams["sort_dir"] =
      sortDir === "asc" ? "desc" : "asc";

    if (params !== sortBy) {
      direction = "asc";
    }

    setSortBy(params as BrowseParams["sort_by"]);
    setSortDir(direction);
    await getSoalData({
      page: 1,
      sort_by: params as BrowseParams["sort_by"],
      sort_dir: direction,
    });
  };

  const handleModal = async (data: BrowseItem) => {
    if (data.type === "question") {
      const response = await getDetail(data.id);

      if (response) {
        setShowBankSoal(true);
        setDetailBankSoal(response);
      }

      return;
    }

    setShowModal(true);
    setInfoFolder(data);
  };

  const handleMove = (item: BrowseItem) => {
    setShowMoveFolder(true);
    setInfoFolder(item);
  };

  const onClickFolder = async (item: Crumb, isCrumb = false) => {
    let updatedCrumb: Crumb[] = JSON.parse(JSON.stringify(crumbs));
    if (isCrumb) {
      const crumbIndex = updatedCrumb.findIndex((val) => val.id === item.id);
      updatedCrumb = updatedCrumb.slice(0, crumbIndex + 1);
    } else {
      updatedCrumb = [...updatedCrumb, item];
    }
    setSearch("");
    await getSoalData({ page: 1, per_page: 10, directory_id: item.id });
    setCrumbs(updatedCrumb);
    setParentId(item.id);
  };

  const handleEdit = (item: BrowseItem) => {
    if (item.type === "directory") {
      setShowEdit(true);
      setInfoFolder(item);
    } else {
      navigate(`edit/${item.id}`, {
        relative: "path",
        state: { directory_id: parentId },
      });
    }
  };

  const handleDelete = (item: BrowseItem) => {
    setShowDelete(true);
    setInfoFolder(item);
  };

  return (
    <>
      <Breadcrumbs
        crumbs={crumbs}
        onClick={(item) => onClickFolder(item, true)}
      />
      <div className="flex justify-between items-center mb-5">
        <div className="bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center">
          <FontAwesomeIcon icon={icon({ name: "search" })} />
          <input
            className="w-full"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({ name: "close" })}
              onClick={clearSearch}
              className="cursor-pointer"
            />
          )}
        </div>
        <AddModule directoryId={parentId} onSuccess={getSoalData} />
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={currentLimit}
        currentPage={currentPage}
        total={total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        handleFirstColumn="w-[40%]"
      >
        <>
          {data.length ? (
            data?.map((item: BrowseItem) => {
              const { id, name, type, updated_at, grade_name, difficulty } =
                item;
              const isFolder = type === "directory";
              const iconType = isFolder
                ? icon({ name: "folder" })
                : icon({ name: "file-lines", style: "regular" });
              const itemCrumb = {
                id,
                label: name,
              };

              return (
                <tr key={`${type}-${id}`} className="border-b">
                  <td className="w-[20%] p-4">
                    {isFolder ? (
                      <button
                        className="flex gap-x-2 items-baseline"
                        onClick={() => onClickFolder(itemCrumb)}
                      >
                        <FontAwesomeIcon
                          className="w-[0.875rem] h-[0.875rem]"
                          icon={iconType}
                        />
                        <span className="text-blue-500 text-start">{name}</span>
                      </button>
                    ) : (
                      <div className="flex gap-x-2 items-baseline">
                        <FontAwesomeIcon
                          className="w-[0.875rem] h-[0.875rem]"
                          icon={iconType}
                        />
                        <span className="cursor-default text-start">
                          {name}
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="p-4">
                    {getFormatDate(updated_at, "DD MMM YYYY")}
                  </td>
                  <td className="p-4">
                    <DifficultyBadge difficulty={difficulty} />
                  </td>
                  <td className="p-4">{grade_name}</td>
                  <td className="p-4 text-end">
                    <FontAwesomeIcon
                      icon={icon({ name: "info" })}
                      onClick={() => handleModal(item)}
                      className="cursor-pointer"
                      title="info"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "pen" })}
                      onClick={() => handleEdit(item)}
                      className="cursor-pointer ml-2"
                      title="edit"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "trash-alt" })}
                      onClick={() => handleDelete(item)}
                      className="cursor-pointer ml-2"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "circle-chevron-right" })}
                      onClick={() => handleMove(item)}
                      className="cursor-pointer ml-2"
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                {loading ? <Spinner /> : <span>Tidak ada data.</span>}
              </td>
            </tr>
          )}
        </>
      </Table>
      <ModalFolder
        show={showModal}
        onClose={() => setShowModal(false)}
        data={infoFolder}
        crumbs={crumbs}
      />
      <ModulSoal
        show={showBankSoal}
        onClose={() => setShowBankSoal(false)}
        data={detailBankSoal}
      />
      <EditFolder
        show={showEdit}
        directoryId={parentId}
        onClose={() => setShowEdit(false)}
        onSuccess={getSoalData}
        isEdit
        name={infoFolder?.name}
        id={infoFolder?.id}
      />
      <DeleteSoal
        data={infoFolder}
        show={showDelete}
        onClose={() => setShowDelete(false)}
        onSuccess={getSoalData}
      />

      <MoveFolder
        show={showMoveFolder}
        onClose={() => setShowMoveFolder(false)}
        item={infoFolder}
        onSuccess={getSoalData}
        directoryId={parentId}
      />
    </>
  );
};

export default BankSoal;
